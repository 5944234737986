$(document).ready(function (e) {
	let $search = $('#search-form');

	if (!$search.length) {
		return;
	}

	$('#region').on('change', function () {
		updateNextField($(this), $('#province'));
	});

	$('#province').on('change', function () {
		updateNextField($(this), $('#city'));
	});

	$('#city').on('change', function () {
		updateNextField($(this), $('#area'));
	});

	function updateNextField ($this, $next) {
		const disabled = $this.val() === '';
		$next.prop('disabled', disabled);
		if (disabled) {
			$next.val('');
		} else {
			// remove old list elements (if any) leaving the empty placeholder --
			$next.children('option[value!=""]').remove();

			// load new elements from api
			$.ajax({
				url: '/api/get-rea-item-properties',
				dataType: 'json',
				data: {
					field: $next.data('field'),
					filterField: $this.data('field'),
					filterValue: $this.val()
				},
				method: 'GET'
			})
			.done(function (res) {
				// put new elements in dropdown
				if (res.Error == "") {
					for (const item of res.Items) {
						$next.append(`<option value="${item}">${item}</option>`);
					}
				}
			})
			.fail(function () {
				console.log(`unable to load list for field ${next}`)
			})
		}
		$next.change();
	}
});
