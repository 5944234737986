$(document).ready(function (e) {
	let $contacts = $('#form-info');
	if (!$contacts.length) {
		return;
	}

	let $submit = $('#contact-form-submit');
	const $inputs = $contacts.find(':input');
	const $feedback = $('#message-feedback');
	$feedback.collapse();
	const $success = $('#message-success');
	const $fail = $('#message-fail');
	const $token = $('#token');

	// ----------------------- ReCaptcha
	window.recaptchaOk = function (token) {
		$token.val(token);
		grecaptcha.reset();

		submitConsent();
		const params = $contacts.serialize();
		$inputs.prop('disabled', true);
		$.ajax({
			url: $contacts.attr('action'),
			dataType: 'json',
			data: params,
			method: 'POST'
		})
			.done(function (res) {
				_iub.cons.sendData();
				reportSuccess();
			})
			.fail(function () {
				reportFailure();
			})
			.always(function () {
			})
	};

	$submit.on('click', function(e) {
		e.preventDefault();
		if ($contacts[0].checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			$contacts.addClass('was-validated');
			return;
		}
		grecaptcha.execute();
	});

	function reportSuccess() {
		$fail.hide();
		$success.show();
		$feedback.collapse('show');
	}

	function reportFailure() {
		$inputs.prop('disabled', false);
		$success.hide();
		$fail.show();
		$feedback.collapse('show');
	}

	function submitConsent() {
		_iub.cons_instructions.push(['submit', {
			form: {
				selector: $contacts[0],
			},
			consent: {
				legal_notices: [
					{
						identifier: 'privacy_policy'
					},
					{
						identifier: 'terms'
					}
				]
			}
		}
		])
	}

});
