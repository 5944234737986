import '../../style/src/custom.scss';
import 'jquery';
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';
import { LuminousGallery } from 'luminous-lightbox'
import Masonry from 'masonry-layout';
import Imagesloaded from 'imagesloaded';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

library.add(faFacebookSquare, faInstagram);

dom.watch();

$(document).ready(function(e) {

	const $toggle = $('#toggle');
	const $overlay = $('#overlay');

	$toggle.on('click', function (e) {
		$(this).toggleClass('active');
		$overlay.toggleClass('open');
	});

	$('.overlay-menu a').on('click', function () {
		$toggle.toggleClass('active');
		$overlay.toggleClass('open');
	});

	const $grid = $('#masonry-grid');
	if ($grid.length) {
		Imagesloaded($grid[0], function() {
			let msnry = new Masonry('#masonry-grid', {
				columnWidth: '.grid-sizer',
				itemSelector: '.grid-item',
				percentPosition: true,
				transitionDuration: 0,
				gutter: 0,
			});

			msnry.layout();

			new LuminousGallery(
				document.querySelectorAll(".luminous-gallery"),
				{
					arrowNavigation: true
				},
				{
					caption: function(trigger) {
						return trigger.querySelector("img").getAttribute("alt");
					}
				}
			);
		});
	}


});
